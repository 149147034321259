import { useEffect, useRef, useState } from 'react';
import '../App.scss';
import { Button } from '@mui/base/Button';
import { Autocomplete, TextField, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Projects, ResponseClient, CorePeopleMetrics, PeopleAffected, ProjectStatus, Responses } from '../types';
import { APIPATH } from '../App';
import { Row } from 'react-bootstrap';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function Project() {
  const defaultProject: Projects = {
    userId: "",
    projectTitle: "",
    companyReferenceNumber: "",
    programmeTitle: "",
    businessUnitAccountable: "",
    overviewOfChange: "",
    peopleExperienceToday: "",
    peopleExperienceFuture: "",
    startDate: "",
    finishDate: "",
    corePeopleMetrics: [{}],  // Initialize with three empty objects
    criticalSuccessFactors: [""],  // Initialize with three empty strings
    peopleAffected: [{}],  // Initialize with three empty objects
  };

  const defaultResponse: Responses = {
    projectId: "",
    questionChangeImpacts: undefined
  };

  const [project, setProject] = useState<Projects>(defaultProject);
  const [projectTitle, setProjectTitle] = useState(project.projectTitle);
  const [companyReferenceNumber, setCompanyReferenceNumber] = useState(project.companyReferenceNumber);
  const [programmeTitle, setProgrammeTitle] = useState(project.programmeTitle);
  const [businessUnitAccountable, setBusinessUnitAccountable] = useState(project.businessUnitAccountable);
  const [overviewOfChange, setOverviewOfChange] = useState(project.overviewOfChange);
  const [peopleExperienceToday, setPeopleExperienceToday] = useState(project.peopleExperienceToday);
  const [peopleExperienceFuture, setPeopleExperienceFuture] = useState(project.peopleExperienceFuture);
  const [startDate, setStartDate] = useState(project.startDate);
  const [finishDate, setFinishDate] = useState(project.finishDate);
  const [corePeopleMetrics, setCorePeopleMetrics] = useState<CorePeopleMetrics[]>([{ expectedChangeInExperience: "", toBe: "", asIs: "" }]);
  const [criticalSuccessFactors, setCriticalSuccessFactors] = useState<string[]>([""]);
  const [peopleAffected, setPeopleAffected] = useState<PeopleAffected[]>([{ teamOrUnitAffected: "", fteCount: "" }]);

  const [response, setResponse] = useState<Responses>(defaultResponse);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectId = params.get('p');

  const [currentPage, setCurrentPage] = useState(1);

  const responseClient = new ResponseClient(APIPATH);
  const navigate = useNavigate();

  const projectForm = useRef<HTMLFormElement>(null);
  const checkFormValidity = () => {
    if (projectForm.current) {
      const isValid = projectForm.current.checkValidity();
  
      // Custom validation using state
      if (startDate && finishDate && new Date(finishDate) <= new Date(startDate)) {
        console.error('Finish date must be after the start date');
        alert('Finish date must be after the start date');
        return false;
      }
  
      console.log(isValid ? 'Form is valid' : 'Form is invalid');
  
      // Trigger native validation messages and styles
      if (!isValid) {
        projectForm.current.reportValidity();
      }
  
      return isValid;
    }
  };

  const functionOptions = [
    'Information Technology',
    'Operations',
    'Sales',
    'Customer Service',
    'Human Resources (HR)',
    'Marketing and Advertising',
    'Accounting and Finance',
    'Supply Chain Management',
    'Business Development and Growth',
    'Project Management Office (PMO)',
    'Legal Department',
    'Research and Development (R&D)',
    'Logistics',
    'Internal Communications',
    'Quality Assurance',
    'Production',
    'Purchasing',
    'Facilities Management',
    'Training and Development',
    'Risk Management',
    'Business Intelligence',
    'Corporate Strategy',
    'Compensation and Benefits',
    'Corporate Social Responsibility (CSR)',
    'Mergers and Acquisitions',
    'Environmental Sustainability',
    'Internal Audit',
    'Regulatory Compliance',
    'Product Management',
    'Sales Operations',
    'Financial Planning and Analysis (FP&A)',
    'Tax Management',
    'External Affairs',
    'Event Management',
    'Health and Safety',
    'Quality Control',
    'Supplier Relations',
    'Customer Experience',
    'Corporate Development',
    'Legal Compliance',
    'Strategic Planning',
    'Business Analysis',
    'Partner Management',
    'Employee Relations',
    'Retail Operations',
    'Innovation Management',
    'Internal Consulting',
    'Digital Transformation',
    'Change Management Office (CMO)',
    'Business Entity 1',
    'Business Entity 2',
    'Business Entity 3',
    'Business Entity 4',
  ];

  const getProject = () => {
    if (projectId !== null) {
      responseClient.getProjectFromId(projectId)
        .then(proj => {
          if (proj !== undefined) {
            setProject(proj);
            setProjectTitle(proj.projectTitle);
            setCompanyReferenceNumber(proj.companyReferenceNumber);
            setProgrammeTitle(proj.programmeTitle);
            setBusinessUnitAccountable(proj.businessUnitAccountable);
            setOverviewOfChange(proj.overviewOfChange);
            setPeopleExperienceToday(proj.peopleExperienceToday);
            setPeopleExperienceFuture(proj.peopleExperienceFuture);
            setStartDate(proj.startDate);
            setFinishDate(proj.finishDate);
            setCorePeopleMetrics(proj.corePeopleMetrics || [{}, {}, {}]);
            setCriticalSuccessFactors(proj.criticalSuccessFactors || ["", "", ""]);
            setPeopleAffected(proj.peopleAffected || [{}, {}, {}]);

            if (!(proj.status === ProjectStatus.Inception || proj.status === ProjectStatus.InProgress)) {
              navigate('../thank-you/', { replace: true }); // Updating the project, already got a response
            }
          }
        }
        )
        .catch(error => {
          console.error('Error fetching project:', error);
        });
    }
  };

  const getResponse = () => {
    if (projectId !== null) {
      responseClient.getResponseFromProjectId(projectId)
        .then(resp => {
          if (resp !== undefined) {
            setResponse(resp);
          }
        })
    }
  };

  useEffect(() => {
    getProject();
    getResponse();
  }, [projectId]);

  const handleMetricChange = (index: number, field: string, value: string) => {
    const newMetrics = corePeopleMetrics.map((metric, i) => (
      i === index ? { ...metric, [field]: value } : metric
    ));
    setCorePeopleMetrics(newMetrics);
  };

  const handleCSFChange = (index: number, value: string) => {
    const newCSFs = criticalSuccessFactors.map((csf, i) => (
      i === index ? value : csf
    ));
    setCriticalSuccessFactors(newCSFs);
  };

  const handlePeopleAffectedChange = (index: number, field: string, value: string) => {
    const newPeople = peopleAffected.map((person, i) => (
      i === index ? { ...person, [field]: value } : person
    ));
    setPeopleAffected(newPeople);
  };

  const addCorePeopleMetric = () => {
    setCorePeopleMetrics([...corePeopleMetrics, { expectedChangeInExperience: "", toBe: "", asIs: "" }]);
  };

  const removeCorePeopleMetric = (index: number) => {
    setCorePeopleMetrics(corePeopleMetrics.filter((_, i) => i !== index));
  };

  const addCriticalSuccessFactor = () => {
    setCriticalSuccessFactors([...criticalSuccessFactors, ""]);
  };

  const removeCriticalSuccessFactor = (index: number) => {
    setCriticalSuccessFactors(criticalSuccessFactors.filter((_, i) => i !== index));
  };

  const addPeopleAffected = () => {
    setPeopleAffected([...peopleAffected, { teamOrUnitAffected: "", fteCount: "" }]);
  };

  const removePeopleAffected = (index: number) => {
    setPeopleAffected(peopleAffected.filter((_, i) => i !== index));
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const updatedProject: Projects = {
        _id: project._id,
        status: ProjectStatus.InProgress,
        userId: project.userId,
        projectTitle: projectTitle,
        companyReferenceNumber: companyReferenceNumber,
        programmeTitle: programmeTitle,
        businessUnitAccountable: businessUnitAccountable,
        overviewOfChange: overviewOfChange,
        peopleExperienceToday: peopleExperienceToday,
        peopleExperienceFuture: peopleExperienceFuture,
        startDate: startDate,
        finishDate: finishDate,
        corePeopleMetrics: corePeopleMetrics,
        criticalSuccessFactors: criticalSuccessFactors,
        peopleAffected: peopleAffected,
      };

      if (response && response._id !== null) {
        navigate('../questions/?r=' + response._id, { replace: true }); // Updating the project, already got a response
      } else {
        const createdProject = await responseClient.upsertProject(updatedProject);
        const createdResponse = await responseClient.createResponseForProjectId(createdProject._id);
        navigate('../questions/?r=' + createdResponse._id, { replace: true });
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    project && (
      <div className="main pb-4 d-flex flex-column align-items-center">
        <h1 className="mb-6">Project brief and initial people experience assessment</h1>
        <div className="mb-6"><p>By using Journey, you agree to the <a target="_blank" href="/terms">terms and conditions.</a></p></div>
        <form
          ref={projectForm}
          onSubmit={handleSubmit}
          className="d-flex flex-column align-items-center questionnaire-form"
        >
          {currentPage === 1 && (
            <>
              <TextField
                label="Project Title"
                variant="outlined"
                className="mb-4"
                style={{ width: "100%" }}
                value={projectTitle}
                onChange={(e) => setProjectTitle(e.target.value)}
                required
              />
              <TextField
                label="Company Reference Number (optional)"
                variant="outlined"
                className="mb-4"
                style={{ width: "100%" }}
                value={companyReferenceNumber}
                onChange={(e) => setCompanyReferenceNumber(e.target.value)}
              />
              <TextField
                label="Programme Title (optional)"
                variant="outlined"
                className="mb-4"
                style={{ width: "100%" }}
                value={programmeTitle}
                onChange={(e) => setProgrammeTitle(e.target.value)}
              />
              <Autocomplete
                className="mb-4"
                disablePortal
                options={functionOptions}
                style={{ width: "100%" }}
                value={businessUnitAccountable} // Add value prop to set the initial value
                onChange={(_, newValue) => {
                  newValue && setBusinessUnitAccountable(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ width: "100%" }}
                    label="Business Unit Accountable"
                    required
                  />
                )}
              />
              <TextField
                label="Overview of Change"
                variant="outlined"
                className="mb-4"
                style={{ width: "100%" }}
                multiline
                rows={8}
                value={overviewOfChange}
                onChange={(e) => setOverviewOfChange(e.target.value)}
                required
              />
              <TextField
                label="People Experience Today"
                variant="outlined"
                className="mb-4"
                multiline
                rows={4}
                style={{ width: "100%" }}
                value={peopleExperienceToday}
                onChange={(e) => setPeopleExperienceToday(e.target.value)}
                required
              />
              <TextField
                label="People Experience Future"
                variant="outlined"
                className="mb-4"
                multiline
                style={{ width: "100%" }}
                rows={4}
                value={peopleExperienceFuture}
                onChange={(e) => setPeopleExperienceFuture(e.target.value)}
                required
              />
              <TextField
                label="Start Date"
                variant="outlined"
                className="mb-4"
                type="date"
                InputLabelProps={{ shrink: true }}
                style={{ width: "100%" }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
              <TextField
                label="Finish Date"
                variant="outlined"
                className="mb-6"
                type="date"
                InputLabelProps={{ shrink: true }}
                style={{ width: "100%" }}
                value={finishDate}
                onChange={(e) => setFinishDate(e.target.value)}
                required
              />
              <Button className="styled-button" onClick={() => {
                if (checkFormValidity()) {
                  setCurrentPage(2)
                }
              }
              }
              >
                Continue
              </Button>
            </>
          )}
          {currentPage === 2 && (
            <>
              <a
                className="mb-4"
                data-testid="continue-button"
                type="submit"
                onClick={() => setCurrentPage(1)}
              >
                <ArrowBackIosIcon />Back
              </a>

              <h3 className="mb-4">Core People Metrics</h3>

              {corePeopleMetrics.map((metric, index) => (
                <div key={index} className="mb-4 w-100">
                  <Row>
                    <div className="col-6">
                      <TextField
                        label="Expected Change in Experience"
                        variant="outlined"
                        value={metric.expectedChangeInExperience}
                        onChange={(e) => handleMetricChange(index, "expectedChangeInExperience", e.target.value)}
                        className="mb-4"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-3">
                      <TextField
                        label="As-is"
                        variant="outlined"
                        value={metric.asIs}
                        onChange={(e) => handleMetricChange(index, "asIs", e.target.value)}
                        className="mb-4"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-3">
                      <TextField
                        label="To-be"
                        variant="outlined"
                        value={metric.toBe}
                        onChange={(e) => handleMetricChange(index, "toBe", e.target.value)}
                        className="mb-4"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Row>
                  <Button className="" onClick={() => removeCorePeopleMetric(index)}>Remove</Button>
                </div>
              ))}

              <Button className="styled-button mb-4" onClick={addCorePeopleMetric}>Add Core People Metric</Button>

              <h3 className="mb-4">Critical Success Factors</h3>

              {criticalSuccessFactors.map((factor, index) => (
                <div key={index} className="csf mb-4 w-100">
                  <TextField
                    label={`Critical Success Factor ${index + 1}`}
                    variant="outlined"
                    value={factor}
                    onChange={(e) => handleCSFChange(index, e.target.value)}
                    style={{ width: "100%" }}
                    className="mb-4"
                  />
                  <Button onClick={() => removeCriticalSuccessFactor(index)}>Remove</Button>
                </div>
              ))}

              <Button className="styled-button mb-4" onClick={addCriticalSuccessFactor}>Add Critical Success Factor</Button>

              <h3 className="mb-4">People Affected</h3>

              {peopleAffected.map((person, index) => (
                <div key={index} className="person mb-4 w-100">
                  <Row>
                    <div className="col-9">
                      <TextField
                        label="Team or Unit Affected"
                        variant="outlined"
                        value={person.teamOrUnitAffected}
                        onChange={(e) => handlePeopleAffectedChange(index, "teamOrUnitAffected", e.target.value)}
                        style={{ width: "100%" }}
                        className="mb-4"
                      />
                    </div>
                    <div className="col-3">
                      <TextField
                        label="FTE Count"
                        variant="outlined"
                        value={person.fteCount}
                        onChange={(e) => handlePeopleAffectedChange(index, "fteCount", e.target.value)}
                        style={{ width: "100%" }}
                        className="mb-4"
                      />
                    </div>
                  </Row>
                  <Button onClick={() => removePeopleAffected(index)}>Remove</Button>
                </div>
              ))}

              <Button className="styled-button mb-4" onClick={addPeopleAffected}>Add People Affected</Button>

              <Button type="submit" className="styled-button">
                Submit
              </Button>
            </>
          )}
        </form>
      </div>
    )
  );
}

export default Project;
